// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

body {
    background-color: #ffffff!important;
}

// Bootstrap
@import '~bootstrap/scss/bootstrap';
